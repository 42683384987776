import Typography from "typography"
import theme from 'typography-theme-fairy-gates'

theme.bodyFontFamily = ['Source Sans Pro', 'sans-serif'];
theme.googleFonts = [
  {
    name: 'Work Sans',
    styles: ['500', '600'],
  },
  {
    name: 'Source Sans Pro',
    styles: ['400', '400i'],
  },
]

const typography = new Typography(theme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
